var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-detail-definition","scrollable":"","centered":"","size":"lg","title":_vm.detail && _vm.detail.id
        ? _vm.$t('lbl_deliverable_definition_config_detail')
        : _vm.$t('lbl_add_new'),"cancel-title":_vm.$t('lbl_cancel'),"ok-title":_vm.detail && _vm.detail.id ? _vm.$t('lbl_update') : _vm.$t('lbl_add_new'),"cancel-variant":"outline-secondary"},on:{"ok":_vm.handleOk}},[_c('validation-observer',{ref:"definitionFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('lbl_title_name_definition_config'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2 required",attrs:{"label":_vm.$t('lbl_title_name_definition_config')}},[_c('b-form-input',{model:{value:(_vm.detail.title),callback:function ($$v) {_vm.$set(_vm.detail, "title", $$v)},expression:"detail.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('lbl_deliverable_documents_title__english_')}},[_c('b-form-input',{model:{value:(_vm.detail.title_en),callback:function ($$v) {_vm.$set(_vm.detail, "title_en", $$v)},expression:"detail.title_en"}})],1)],1)],1),_c('b-row',{staticClass:"mb-50"},[_c('b-col',{staticClass:"flex items-center gap-4",attrs:{"md":"9"}},[_c('p',{staticClass:"mb-md-0 flex-1 required"},[_vm._v(_vm._s(_vm.$t('lbl_list_vietnamese')))]),_c('p',{staticClass:"mb-md-0 flex-1"},[_vm._v(_vm._s(_vm.$t('lbl_list_english')))])])],1),_vm._l((_vm.definition_items),function(item,index){return _c('b-row',{key:item.id || item.code || index,ref:"row",refInFor:true,attrs:{"id":item.id || item.code || index}},[_c('b-col',{staticClass:"flex items-center gap-4",attrs:{"md":"9"}},[_c('b-form-group',{staticClass:"mb-md-0 flex-1"},[_c('validation-provider',{attrs:{"vid":("input-" + (item.id || item.code || index)),"name":_vm.$t('lbl_deliverable_documents_title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","id":("input-" + (item.id || item.code || index)),"placeholder":_vm.$t('lbl_eg__consultation__test_results____'),"disabled":false},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-md-0 flex-1"},[_c('b-form-input',{attrs:{"type":"text","id":("input-" + (item.id || item.code || index)),"placeholder":_vm.$t('lbl_eg__consultation__test_results____'),"disabled":false},model:{value:(item.title_en),callback:function ($$v) {_vm.$set(item, "title_en", $$v)},expression:"item.title_en"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',[(_vm.definition_items.length > 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"px-1 py-50 mr-75",attrs:{"title":_vm.$t('lbl_remove'),"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index, _vm.definition_items)}}},[_c('feather-icon',{attrs:{"size":"22","icon":"Trash2Icon"}}),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('lbl_remove')))])],1):_vm._e(),(index === _vm.definition_items.length - 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"px-1 py-50",attrs:{"title":_vm.$t('lbl_add_new'),"variant":"outline-primary"},on:{"click":function($event){return _vm.addNewDeliverable(_vm.definition_items)}}},[_c('feather-icon',{attrs:{"size":"22","icon":"PlusIcon"}}),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('lbl_add_new')))])],1):_vm._e()],1)]),(
              index !== _vm.definition_items.length - 1 &&
              _vm.definition_items.length > 1
            )?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e()],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }