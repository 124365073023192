<template>
  <div>
    <b-modal
      id="modal-detail-definition"
      scrollable
      centered
      size="lg"
      :title="
        detail && detail.id
          ? $t('lbl_deliverable_definition_config_detail')
          : $t('lbl_add_new')
      "
      :cancel-title="$t('lbl_cancel')"
      :ok-title="detail && detail.id ? $t('lbl_update') : $t('lbl_add_new')"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <validation-observer ref="definitionFormValidation">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                :name="$t('lbl_title_name_definition_config')"
                rules="required"
              >
                <b-form-group
                  :label="$t('lbl_title_name_definition_config')"
                  class="mb-2 required"
                >
                  <b-form-input v-model="detail.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('lbl_deliverable_documents_title__english_')"
                class="mb-2"
              >
                <b-form-input v-model="detail.title_en" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-50">
            <b-col md="9" class="flex items-center gap-4">
              <p class="mb-md-0 flex-1 required">{{ $t('lbl_list_vietnamese') }}</p>
              <p class="mb-md-0 flex-1">{{ $t('lbl_list_english') }}</p>
            </b-col>
          </b-row>
          <b-row
            v-for="(item, index) in definition_items"
            :id="item.id || item.code || index"
            :key="item.id || item.code || index"
            ref="row"
          >
            <!-- Item Name -->
            <b-col md="9" class="flex items-center gap-4">
              <b-form-group class="mb-md-0 flex-1">
                <validation-provider
                  #default="{ errors }"
                  :vid="`input-${item.id || item.code || index}`"
                  :name="$t('lbl_deliverable_documents_title')"
                  rules="required"
                >
                  <b-form-input
                    type="text"
                    v-model="item.title"
                    :id="`input-${item.id || item.code || index}`"
                    :placeholder="$t('lbl_eg__consultation__test_results____')"
                    :disabled="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="mb-md-0 flex-1">
                <b-form-input
                  type="text"
                  v-model="item.title_en"
                  :id="`input-${item.id || item.code || index}`"
                  :placeholder="$t('lbl_eg__consultation__test_results____')"
                  :disabled="false"
                />
              </b-form-group>
            </b-col>

            <!-- Buttons -->
            <b-col md="3">
              <div>
                <b-button
                  v-b-tooltip.hover
                  v-if="definition_items.length > 1"
                  :title="$t('lbl_remove')"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="px-1 py-50 mr-75"
                  @click="removeItem(index, definition_items)"
                >
                  <feather-icon size="22" icon="Trash2Icon" />
                  <span class="sr-only">{{ $t('lbl_remove') }}</span>
                </b-button>
                <b-button
                  v-if="index === definition_items.length - 1"
                  v-b-tooltip.hover
                  :title="$t('lbl_add_new')"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-primary"
                  class="px-1 py-50"
                  @click="addNewDeliverable(definition_items)"
                >
                  <feather-icon size="22" icon="PlusIcon" />
                  <span class="sr-only">{{ $t('lbl_add_new') }}</span>
                </b-button>
              </div>
            </b-col>
            <b-col
              cols="12"
              v-if="
                index !== definition_items.length - 1 &&
                definition_items.length > 1
              "
            >
              <hr />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import appUtils from '@/utils/appUtils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DefinitionDetail',
  props: {
    recordDetail: {
      type: Object,
      default: {}
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    const initialItem = {
      code: appUtils.randomCode(10),
      title: ''
    }
    return {
      required,
      initialItem,
      detail: {
        id: '',
        title: '',
        title_en: ''
      },
      definition_items: [],
      appUtils
    }
  },
  mounted() {
    this.setStateData(this.recordDetail)
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      this.resetForm()
    })
  },
  watch: {
    recordDetail: {
      handler(data) {
        this.setStateData(data)
      }
    }
  },
  methods: {
    setStateData(data) {
      this.detail = {
        id: data?.ID || '',
        title: data?.title || '',
        title_en: data?.product_delivery_attachments_config_multilanguage?.find(
          (elm) => elm.language == 'en'
        )?.title
      }
      this.definition_items = data?.product_delivery_attachment_items?.length
        ? data?.product_delivery_attachment_items?.map((elm) => {
            return {
              title: elm.title,
              title_en: elm.definition_item_multilangs?.find(
                (item) => item.language == 'en'
              )?.title,
              product_delivery_attachments_config_id:
                elm.product_delivery_attachments_config_id
            }
          })
        : [{ ...this.initialItem }]
    },
    resetForm() {
      this.detail = {
        id: '',
        title: '',
        title_en: ''
      }
      this.definition_items = [{ ...this.initialItem }]
    },
    addNewDeliverable(data) {
      data.push({
        code: appUtils.randomCode(10),
        title: '',
        title_en: ''
      })
    },
    removeItem(index, data, callback) {
      data.splice(index, 1)

      if (callback instanceof Function) {
        callback()
      }
    },
    handleOk(bvEvent) {
      bvEvent.preventDefault()
      this.handleSubmit()
    },
    addForm() {
      try {
        const userData = appUtils.getLocalUser()
        this.$store
          .dispatch('categories/createDeliveryDefinitionConfigs', {
            title: this.detail.title,
            supplier_id: userData?.supplierInfo?.id,
            definition_items: this.definition_items.map((elm) => {
              return {
                title: elm.title,
                definition_item_multilangs: [
                  {
                    title: elm.title_en,
                    language: 'en'
                  }
                ]
              }
            }),
            product_definition_config_multilangs: [
              {
                title: this.detail.title_en,
                language: 'en'
              }
            ]
          })
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('lbl_add_new_successfully'),
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
          })
      } catch (error) {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('lbl_an_error_occurred_while_adding_new'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    },
    updateForm() {
      try {
        const userData = appUtils.getLocalUser()
        const data = {
          title: this.detail.title,
          supplier_id: userData?.supplierInfo?.id,
          definition_items: this.definition_items.map((elm) => {
            return {
              title: elm.title,
              definition_item_multilangs: [
                {
                  title: elm.title_en,
                  language: 'en'
                }
              ]
            }
          }),
          product_definition_config_multilangs: [
            {
              title: this.detail.title_en,
              language: 'en'
            }
          ]
        }
        this.$store
          .dispatch('categories/updateDeliveryDefinitionConfigsById', {
            id: this.detail?.id,
            data
          })
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('lbl_updated_successfully'),
                icon: 'CheckIcon',
                variant: 'success'
              }
            })
          })
      } catch (error) {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: this.$t('lbl_an_error_occurred_while_updating'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    },
    handleSubmit() {
      this.$refs.definitionFormValidation.validate().then(async (success) => {
        if (success) {
          if (this.detail?.id) {
            this.updateForm()
          } else {
            this.addForm()
          }

          this.$emit('refresh')
          this.$nextTick(() => {
            this.$bvModal.hide('modal-detail-definition')
          })
        }
      })
    }
  }
}
</script>

<style>
</style>
